import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import App from 'App';
import { SidebarProvider } from 'contexts/SidebarContext';
import * as serviceWorker from 'serviceWorker';

import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// import { initializeApp, refreshToken } from 'firebase-admin/app';
// const app = initializeApp();
// const myRefreshToken = '...'; // Get refresh token from OAuth2 flow

// initializeApp({
//   credential: refreshToken(myRefreshToken),
//   databaseURL: 'https://pod-mmo-toolkis.firebaseio.com'
// });


root.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>
);

serviceWorker.unregister();
