import config from 'config/config';
import XhrHttps from './XhrHttps';
// import jwt from 'jsonwebtoken';
import { useJwt, isExpired, decodeToken } from "react-jwt";

class AuthServices {
  domain: string = config.domain + 'auth';

  http: any = null;

  constructor() {
    var setInit = async () =>
      new Promise((resolve, reject) => {
        resolve(XhrHttps.init());
      });

    setInit().then((res) => {
      this.http = res;
    });
  }

  login = async (data: any) => {
    let url = `${this.domain}/login`;
    let user = await this.http.post(url, data);

    if (user?.data?.token) {
      // const { decodedToken, isExpired } = useJwt(user?.data?.token);
      const decoded = decodeToken(user?.data?.token);
      const isMyTokenExpired = isExpired(user?.data?.token);
      // var decoded = jwt.verify(user.data.token, config.jwtSecret);
      // console.log("===Token===")
      // console.log(decoded)
      sessionStorage.setItem('user', JSON.stringify(user?.data) );//decoded);
    }
    // console.log(Storage.get('user'));
    return user;
  };

  logout = async () => {
    return sessionStorage.removeItem('user');
  };

  regis = async (data: any) => {
    // console.log('---Regis---');
    // console.log(data)
    var res = await this.http.post(`${this.domain}/add`, data);
    return res;
  };
}
export default new AuthServices();
