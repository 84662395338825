import axios, { CreateAxiosDefaults } from 'axios';

class XhrHttps {
  instance = null;

  init = async (
    fakeUserAgent: boolean = false,
    config?: CreateAxiosDefaults<any>
  ) => {
    // let fakeUserAgent = true
    config = config
      ? config
      : { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } };
    if (fakeUserAgent) {
      // const jsonBrown = ['android-browser', 'chrome', 'firefox'];
      // const brow = Math.floor(Math.random() * 3);
      // const arbrow = require(`../../assets/${jsonBrown[brow]}.json`);
      // const au = Math.floor(Math.random() * arbrow.length);
      // const userAgent = arbrow[au];//'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_12_4) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/57.0.2987.133 Safari/537.36';
      // console.log(userAgent);
      // // config.timeout = 30 * 1000;
      // config.headers = { 'X-Custom-Header': 'foobar', 'User-Agent': userAgent }
    }

    this.instance = axios.create({ ...config, withCredentials: false });

    //   axiosinstance.defaults.headers.common['User-Agent'] = userAgent;

    this.instance.interceptors.request.use(
      (config: any) => {
        return config;
      },
      (error: any) => {
        return console.log(error);
      }
    );

    this.instance.interceptors.response.use(
      (response: { data: any }) => {
        // console.log(response.data)
        // console.log("Err res");
        return response.data;
      },
      (error: any) => {
        // console.log("interceptors.response error: " + (error));
        // console.log(error.toString() + " " + error.config.url);
        return JSON.stringify(error);
      }
    );
    return this.instance;
  };
}

export default new XhrHttps();
